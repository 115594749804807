/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getOrientation,
  resetOrientation,
  checkImage,
  getPermissionStatus
} from "../../../utils/methods";
import { saveChallengeData, getActivityChallengeById, editChallengeLibraryV2 } from "../../../redux/actions/challengeActions";
import {
  getUserCompanyDetails,
  allowChallengeEventForSpouseDependent,
} from "../../../redux/actions/userProfileActions";
import { imageErrorMessage } from "../../../utils/constants";
import { withTranslation } from "react-i18next";
import {
  Layout,
  ChallengeHeading,
  Container,
  InputContainer,
  StyledInputV2,
  StyledLabel,
  // UploadPhotoContainer,
  // RemoveImage,
  // InputImage,
  ButtonContainer,
  EditorContainer,
  Border
} from "../../CreateEvent/style";
import StepsHeading from "../ActivityChallengeCreation/steps";
import RadioButton from "../../CustomRadioButton";
import { Main, ToolTip } from "../../AdminTeamCreation/styles";
import HoverButton from "../../common/HoverButtonV2";
import CommonButton from "../../common/CommonButton/CommonButton";
import { Arrows, closeIcon, CommonCloseIcon, NextArrows, UploadIcon } from '../../../utils/icons';
// import { DropdownArrow} from "../../AdminTeamCreation/styles";
import {CustomInput, SurvivorAverage, SurvivorChallengeSection,/* CustomMenuItem,*/ TitleContainer,/* ActivityDropdown,*/ FieldTitle, /*CustomTextArea*/} from '../ActivityChallengeCreation/styles';
import moment from 'moment';
// import { challengeTierPoints } from '../../../../mockData.json';
import { getCompanyLocation } from "../../../redux/actions";
import { GetTeams, GetCompanyTeamUsers } from '../../../redux/constants/apiConstants';
import { http } from '../../../redux/http';
import { ImageUrl } from "../../../utils/constants";
import { challengeTierPoints } from "../../../../mockData";
import CommonDropdown from "../../common/CommonDropDown/CommonDropdown";
import CommonImageUploader from "../../common/CommonImageUploader/CommonImageUploader";
import Editor from "../../CKEditor";
class EditActivityChallenge extends Component {

  constructor(props) {
    super();
    const { survivorChallengeData, isGroupChallenge, isSurvivorChallenge, createLibrary } = props;
    let ImageName = '';
    if (!_.isEmpty(survivorChallengeData) && (props.activityChallengeById || props.editLibrary)) {
      const Array = survivorChallengeData.image.split('/');
      ImageName = Array[Array.length - 1];
    }
    let updatedTiers = challengeTierPoints;
    this.state = {
      title: _.get(survivorChallengeData, 'title', ''),
      date: _.get(survivorChallengeData, 'date', moment().format('MM/DD/YYYY')),
      endDate: _.get(survivorChallengeData, 'endDate', moment().format('MM/DD/YYYY')),
      description: _.get(survivorChallengeData, 'body', ''),
      imageName: ImageName,
      time: _.get(survivorChallengeData, 'time', moment().format('hh:mm a')),
      endTime: _.get(survivorChallengeData, 'endTime', moment().format('hh:mm a')),
      showDateTimeInput: '',
      activityType: isGroupChallenge ? survivorChallengeData.event_type === 'Total Steps' ? 'Total Group Steps' : survivorChallengeData.event_type === 'Total Calories' ? 'Total Group Calories' : 'Total Group Distance' : _.get(survivorChallengeData, 'event_type', 'Total Distance'),
      challengePointValue: _.get(survivorChallengeData, 'challenge_point', '10'),
      challengeMode: createLibrary ? 'Solo' : _.get(survivorChallengeData, 'challenge_mode', ''),
      weeklyIncrement: _.get(survivorChallengeData, 'weekly_increment', null),
      survivorAverage: _.get(survivorChallengeData, 'survivor_average', null),
      amigosToInvite: [],
      openDateTimePicker: '',
      imgSrc: ImageUrl+"/"+_.get(survivorChallengeData, 'image', ''),
      editImage:_.get(survivorChallengeData, 'image', ''),
      companies: _.get(survivorChallengeData, 'companies', []),
      location: [],
      isSpouse: false,
      isDependent: false,
      isEmployee: false,
      challengePoints: updatedTiers,
      challengeLength: 2,
      activityChallengeType: createLibrary ? "Classic challenge" : survivorChallengeData.challenge_type === 'new_group' ? 'Group Challenge' : survivorChallengeData.challenge_type === 'group' ? 'Classic challenge' : 'Survivor challenge',
      survivorChallenge: isSurvivorChallenge,
      groupChallenge: isGroupChallenge,
      imageUpdated: 0,
      challengePointKey: _.get(survivorChallengeData, 'challenge_point', '0')+" "+"Points",
      emailOption: {
        "launch_challenge_email": 1,
        "challenge_reminder_email": 1,
        "challenge_complete_email": 1
      },
      companiesPermission: false,
      locationPermission: false,
      selectedOption: 1,
      surveyId: '',
      surveyType: '',
      dailyGoal: _.get(survivorChallengeData, 'daily_goal', null) || null,
      selectedDailyGoalOption: 1,
      is_team_challenge:0,
      teams:[],
      allTeams:[],
      selectedCompany:{
        "id": 1,
        "company_name": "Woliba",
        "show_biometric": 1,
        "spouse_or_dependent": 1,
        "company_start_date": 2016,
        "show_events": 1,
        "show_education": 1,
        "show_employee_directory": 1 },

      step: 1,
      activityArrow: false,
      challengeType: false,
      pointsArrow: false,
      buttonClickScrollTop: false,
      menuIsOpen: true,
      selectedCompanyCheckBox: 0,
      selectedCompanyValue:null,
      selectedCompanyName: null,
      showLocation: 0,
      city_location: [],
      scheduleChallenge: 0,
      buttonStatus: false,
      activityChallengeValue:survivorChallengeData.challenge_type === 'new_group' ? 'Group Challenge' : survivorChallengeData.challenge_type === 'group' ? 'Classic challenge' : 'Survivor challenge',
      challengeTypeArrow:false
    }
   
  }

  componentDidMount() {
    this.getTeams(this.props.companyDetails.id);
    this.props.activityChallengeById || this.props.editLibrary ? this.fillProps() : null;
    const companiesPermission = getPermissionStatus("Show companies in challenge", this.props.userPermissions);
    const locationPermission = getPermissionStatus("Show locations in challenge", this.props.userPermissions);
    // this.props.getSurveyLibraries();
    // if (companiesPermission) {
  
    // } else {
    this.props.fetchCompanyForUser();
    this.props.allowChallengeEventForSpouseDependent();
    this.setState({
      companies: this.props.userCompany && [this.props.userCompany.id]
    })
    // }
    this.setState({
      companiesPermission: companiesPermission,
      locationPermission: locationPermission
    })
    document.addEventListener('mousedown', this.handleClick);

    if(this.props.role != "ADMIN"){
      this.getTeams(this.props.companyDetails.id);
    }else{
      this.onCompanySelect('selectedCompany', this.state.selectedCompany, this.state.selectedCompany['key'])
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.survivorChallengeData !== prevProps.survivorChallengeData || prevProps.surveyLibraries !== this.props.surveyLibraries) {
      this.props.activityChallengeById || this.props.editLibrary ? this.fillProps() : null;
    }
    if (!this.state.companiesPermission && this.props.userCompany !== prevProps.userCompany) {
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id]
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  onCompanySelect = async(name, value, key = '') => {
    const { challengePointKey } = this.state;
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.get(`${GetCompanyTeamUsers}/${value.id}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({
          allTeams:res,
          [name]: value,
          challengePointKey: key != '' ? key : challengePointKey,
          companies:[value.id]
        })
      }
    }catch(error){
      toast.error(error.message);
    }
    
  };

  fillProps = () => {
    const { survivorChallengeData, isGroupChallenge, isSurvivorChallenge, surveyLibraries } = this.props;
    const Array = survivorChallengeData.image.split('/');
    let ImageName = Array[Array.length - 1];
    let tierPointsIndex = this.state.challengePoints.findIndex((data) => data.value == _.get(survivorChallengeData, 'challenge_point', '0'));
    let surveyIndex = surveyLibraries && surveyLibraries.length > 0 ? surveyLibraries.findIndex((data) => data.id === survivorChallengeData.survey_id) : -1;
    this.setState({
      title: _.get(survivorChallengeData, 'title'),
      date: _.get(survivorChallengeData, 'date', moment().format('MM/DD/YYYY')),
      endDate: _.get(survivorChallengeData, 'endDate', moment().format('MM/DD/YYYY')),
      description: _.get(survivorChallengeData, 'body'),
      activityType: isGroupChallenge ? survivorChallengeData.event_type === 'Total Steps' ? 'Total Group Steps' : survivorChallengeData.event_type === 'Total Calories' ? 'Total Group Calories' : 'Total Group Distance' : _.get(survivorChallengeData, 'event_type', 'Total Distance'),
      challengePointValue: tierPointsIndex == -1 ? 0 : _.get(survivorChallengeData, 'challenge_point', '12'),
      challengeMode: _.get(survivorChallengeData, 'challenge_mode') || 'Solo',
      weeklyIncrement: _.get(survivorChallengeData, 'weekly_increment', null) || null,
      survivorAverage: _.get(survivorChallengeData, 'survivor_average', null) || null,
      imgSrc: ImageUrl+"/"+_.get(survivorChallengeData, 'image', ''),
      editImage:_.get(survivorChallengeData, 'image', ''),
      imageName: ImageName,
      survivorChallenge: isSurvivorChallenge,
      groupChallenge: isGroupChallenge,
      activityChallengeType: survivorChallengeData.challenge_type === 'new_group' ? 'Group Challenge' : survivorChallengeData.challenge_type === 'group' ? 'Classic challenge' : 'Survivor challenge',
      challengePointKey: this.state.challengePointValue+" "+"Points",
      surveyId: survivorChallengeData.survey_id,
      surveyType: surveyLibraries && surveyLibraries.length > 0 ? surveyLibraries[surveyIndex === -1 ? 0 : surveyIndex].survey_name : '',
      selectedOption: surveyIndex === -1 ? 1 : 0,
      dailyGoal: _.get(survivorChallengeData, 'daily_goal', null) || null
    })
  }

  activityChallengeTypes = [
    {"key": "Classic challenge", "value": 'Classic challenge'},
    {"key": "Survivor challenge", "value": 'Survivor challenge'},
    {"key": "Group Challenge", "value": 'Group Challenge'}
  ]

  activityTypesItems= [
    {"key": "Total Distance", "value": 'Total Distance'},
    {"key": "Total Steps", "value": 'Total Steps'},
    {"key": "Total Calories", "value": 'Total Calories'}
  ]

  groupActivityTypesItems= [
    {"key": "Total Group Distance", "value": 'Total Group Distance'},
    {"key": "Total Group Steps", "value": 'Total Group Steps'},
    {"key": "Total Group Calories", "value": 'Total Group Calories'}
  ]

  stepsHeadingEvent = [
    {
      name: 'About Challenge',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Challenge',
      showArrow: true,
      number: 2
    },
    {
      name: 'Edit Challenge',
      showArrow: false,
      number: 3
    }
  ]; 
    
  renderHeading = () => {
    const { survivorChallengeData } = this.props;
    const challengeType= survivorChallengeData.challenge_type=="survivor"?"Survivor Challenge":survivorChallengeData.challenge_type=="group"&&survivorChallengeData.is_team_challenge?this.props.t("Team Challenge"):survivorChallengeData.challenge_type=="new_group"?this.props.t("Group Challenge"):this.props.t("Classic Challenge")
    return(
      <ChallengeHeading color="#005C87">
        <ToolTip
          margin={"auto 15px auto 0"}
          activeValue={"transparent"}
          height="40px"
          width="55px"
        >
          <HoverButton
            title={this.props.t("Close")}
            width="24px"
            height="24px"
            svgPath={CommonCloseIcon()} 
            onClick={() => window.history.back() }
            // backgroundColorActive="#007AB1"
            // backgroundColor="#005C871A"
            activeColor={"#007AB1"}
            fillOpacity={1}
            backgroundColor="#005C871A"
          />
        </ToolTip>
        {this.props.t("Edit the")} {this.props.t(challengeType)}
      </ChallengeHeading>
    )};

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };


  onChangeDescription = (e) => {
    window.console.log("e data is",e)
    const newContent = e.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    trimmedString.length === 0 ||
    trimmedString.length === 7 ||
    trimmedString == "<p></p><p></p>"
      ? this.setState({ description: "" })
      : this.setState({ description: newContent });
  };


  onChange = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const array = document.getElementById('create-challenge-file').value.split("\\");
        reader.onloadend = () => {
          this.setState({
            imgSrc: reader.result,
            imageName: array[array.length - 1],
            editImage: reader.result
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: baseImage,
                editImage: baseImage,
                imageUpdated: this.props.editLibrary ? 1 : 0
              });
            });
          });
        };
      } else {
        toast.error(this.props.t('Please select image file less than 20MB'));
        document.getElementById('create-challenge-file').value = '';
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('create-challenge-file').value = '';
    }
  };

  // removePhoto = () => {
  //   this.setState({
  //     imgSrc: '',
  //     imageName: '',
  //     editImage: ''
  //   });
  // };


  onImageChange = (imageSrc) => {
    this.setState({
      imgSrc: imageSrc,
    });
  };

  removePhoto = () => {
    this.setState({ imgSrc: null });
  };
 

  getTeams = async(companyId)=>{
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.get(`${GetTeams}/${companyId}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({
          allTeams:res
        })
      }
    }catch(error){
      toast.error(error.message);
    }
  }

  stepOneForm = () => {
    const {
      title,
      description,
      step,
    } = this.state;
    const { t } = this.props;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container height="100%" stepBackground="#005C87" color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} heading={this.stepsHeadingEvent}/>
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("Challenge Introduction")}</div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel color="#005C87">{this.props.t("Title of the challenge")}{<span className="imp">*</span>}</StyledLabel>
                  <StyledInputV2
                    placeholder={t("Write title here...")}
                    name="title"
                    onChange={this.onChangeInput}
                    value={title}
                    maxLength="100"
                    bgColor="#ffffff"
                    pColor={"rgba(0, 92, 135, 0.3)"}
                    border="1px solid rgba(0, 122, 177, 0.30)"
                    color="#005C87"
                    isPadding={" 0px 20px 0px 12px"}
                  />
                </InputContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
                <StyledLabel color="#005C87">{this.props.t("Challenge image")}{<span className="imp">*</span>}</StyledLabel>
                {/* <UploadPhotoContainer border bgColor>
                  <div className="innerDivImageContainer">
                    {this.state.imgSrc ? (
                      <img alt="profilre-img" src={this.state.imgSrc}></img>
                    ) : (
                      <div className="uploadImage">
                        <InputImage
                          className="edit-profile-pic "
                          imageReload={1}
                          bgColor
                        >
                          <i className="addImg">{this.props.t("Upload Image")}</i>
                          <input
                            id="create-challenge-file"
                            type="file"
                            name="user"
                            accept=".jpeg, .png, .jpg"
                            multiple={false}
                            onChange={(e) => this.onChange(e)}
                            onClick={(e) =>
                              e.target.files[0] && this.onChange(e)
                            }
                          />
                        </InputImage>
                      </div>
                    )}
                    {this.state.imgSrc.length === 0 ? null : (
                      <RemoveImage onClick={this.removePhoto} bgColor="#F4AAA9">
                        {this.props.t("Remove Photo")}
                      </RemoveImage>
                    )}
                  </div>
                </UploadPhotoContainer> */}
                <CommonImageUploader
                  imgSrc={this.state.imgSrc}
                  t={t}
                  closeIcon={closeIcon}
                  UploadIcon={UploadIcon}
                  onImageChange={this.onImageChange}
                  onRemoveImage={this.removePhoto}
                />
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
                {/* <InputContainer>
                  <StyledLabel color="#005C87">{this.props.t("About The Challenge")}{<span className="imp">*</span>}</StyledLabel>
                  <CustomTextArea
                    isBorder= {'1px solid rgba(0, 122, 177, 0.30)'}
                    placeholder={this.props.t("Write Descirption about the Challenge")}
                    rows="8"
                    name="description"
                    value={description}
                    onChange={this.onChangeInput}
                    data-gramm_editor="false"
                    type="text"
                  />
                </InputContainer> */}

                <InputContainer>
                  <StyledLabel style={{marginTop:"20px"}} color={"#005c87"}>{this.props.t("About the challenge")}{<span>*</span>}</StyledLabel>
                  <EditorContainer>
                    <Editor
                      content={description}
                      onChange={this.onChangeDescription}
                    />
                  </EditorContainer>
                </InputContainer>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  // onSelect = (name, value, key) => {
  //   // const { challengePointKey } = this.state;
  //   (name === "activityChallengeType") ?
  //     this.setState({
  //       [name]: value,
  //       survivorChallenge: value === "Survivor challenge",
  //       groupChallenge: value === "Group Challenge",
  //       activityType: value === "Group Challenge" ? "Total Group Distance" : "Total Distance",
  //       challengePointKey: this.state.challengePointKey,
  //       activityChallengeValue: key
  //     }) :
  //     this.setState({
  //       [name]: value,
  //       // challengePointKey: key != '' ? key : challengePointKey
  //     });
  // };


  onSelect = (name) => {
    this.setState({
      activityChallengeType: name.value,
      survivorChallenge: name.value === "Survivor challenge",
      groupChallenge: name.value === "Group Challenge",
      activityType: name.value === "Group Challenge" ? "Total Group Distance" : "Total Distance",
      challengePointKey: this.state.challengePointKey,
      activityChallengeValue: name.key,
      selectedChallengeType:name.value,});
  };


  onSelectType = (name) => {
    this.setState({
      activityType: name.value,
    });
  };


  onSelectCompany = (name, value, key ) => {
    this.setState({
      [name]: value,
      selectedCompanyName: key
    });
    this.props.getCompanyLocation(value);
    this.getTeams(value);
  };

  isNumberKey = (evt) => {
    let charCode = (evt.which) ? evt.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
  };

  toggleRadioGoalButton = (value) => {
    this.setState({ showLocation: parseInt(value,10) }, () => { });
  };

  stepTwoForm = () => {
    const {
      step,
      groupChallenge,
      activityType,
      survivorChallenge,
      survivorAverage,
      weeklyIncrement,
      challengePoints,
      pointsArrow,
      challengePointKey,
      challengePointValue,
      challengeTypeArrow,
      // activityChallengeType,
      activityChallengeValue,
      activityArrow,
    } = this.state;
    const { t } = this.props;
    const activityTypes = groupChallenge ? this.groupActivityTypesItems : this.activityTypesItems;

    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container height="100%" stepBackground="#005C87" color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} heading={this.stepsHeadingEvent}/>
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("How would you like to customize your challenge?")}</div>
              </div>
              <div className="formBody">
                <TitleContainer widthValue="70%" height="auto" padding="15px 0px 0px 0px">
                  <CommonDropdown
                    tileContainerStyle={{ width: "100%", padding: "0px 0px 0px 0px" }}
                    dropdownStyle={{ top: "90px" }}
                    labelText={t("Challenge types")}
                    isRequired={true}
                    dropdownOpen={challengeTypeArrow}
                    placeholder={t("Select challenge type")}
                    title={activityChallengeValue ? t(activityChallengeValue) : t("Select challenge type")}
                    id="dropdown-recurring"
                    onClick={() => this.setState({ challengeTypeArrow: !challengeTypeArrow })}
                    data={this.activityChallengeTypes}
                    onSelect={this.onSelect}
                    active={activityChallengeValue}
                    onSelectParameter={["value", "key"]}
                    // valueString={"Type"}
                    itemValue={true}
                    valueIndex={1}
                    labelMargin={"0px"}
                    t={t}
                  />
                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
                </TitleContainer>


                <TitleContainer widthValue="70%" height="auto" padding="20px 0px 0px 0px">
                  <CommonDropdown
                    tileContainerStyle={{ width: "100%", padding: "0px 0px 0px 0px" }}
                    dropdownStyle={{ top: "90px" }}
                    labelText={t("Type of activity")}
                    isRequired={true}
                    dropdownOpen={activityArrow}
                    placeholder={t("Select activity type")}
                    title={activityType ? t(activityType) : t("Select activity type")}
                    id="dropdown-recurring"
                    onClick={() => this.setState({ activityArrow: !activityArrow })}
                    data={activityTypes}
                    onSelect={this.onSelectType}
                    active={activityType}
                    onSelectParameter={["value", "key"]}
                    // valueString={"Type"}
                    itemValue={true}
                    valueIndex={1}
                    labelMargin={"0px"}
                    t={t}
                  />
                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 5px 0px'}}/>
                </TitleContainer>
          

                {(groupChallenge) &&
                  <SurvivorAverage width="100%"  isMargin={"15px"}  isHeight={"50px"}>
                    <FieldTitle marginTop={"15px"}>{t('Group goal')}</FieldTitle>
                    <CustomInput
                      isHeight={"50px"}
                      isFont={"rubik"}
                      isRadius={"4px"}
                      isBorder={"1px solid rgba(0, 122, 177, 0.30)"}
                      isBgColor={"white"}
                      type='number'
                      name='survivorAverage'
                      onChange={this.onChangeInput}
                      value={activityType.includes('Distance') ? parseFloat(survivorAverage) : parseInt(survivorAverage,10)}
                      pattern='[0-9]*'
                      min="0"
                      onKeyPress={(e) => this.isNumberKey(e)}
                      placeholder={activityType==="Total Steps"?"Enter E.g. 500 Steps":activityType==="Total Calories"?this.props.t("Enter E.g. 30 Calories"):this.props.t("Enter E.g. 25 Miles")}
                    />
                  </SurvivorAverage>}
                <SurvivorChallengeSection>
                  {(survivorChallenge) && <SurvivorAverage  isMargin={"15px"} isHeight={"50px"}>
                    <FieldTitle>{groupChallenge ? t('Team goal') : t('Week 1 goal')}</FieldTitle>
                    <CustomInput
                      isHeight={"50px"}
                      isFont={"rubik"}
                      isRadius={"4px"}
                      isBorder={"1px solid rgba(0, 122, 177, 0.30)"}
                      isBgColor={"white"}
                      type='number'
                      name='survivorAverage'
                      onChange={this.onChangeInput}
                      value={activityType.includes('Distance') ? parseFloat(survivorAverage) : parseInt(survivorAverage,10)}
                      pattern='[0-9]*'
                      min="0"
                      onKeyPress={(e) => this.isNumberKey(e)}
                      placeholder={activityType==="Total Distance"?this.props.t("Enter E.g. 25,000 Miles"):activityType==="Total Calories"?this.props.t("Enter E.g. 300 Calories"):this.props.t("Enter E.g. 5000 Steps")}
                    />
                  </SurvivorAverage>}
                  {survivorChallenge &&
              <SurvivorAverage  isMargin={"15px"} isHeight={"50px"}>
                <FieldTitle>{t("Weekly increment")}</FieldTitle>
                <CustomInput
                  isHeight={"50px"}
                  isFont={"rubik"}
                  isRadius={"4px"}
                  isBorder={"1px solid rgba(0, 122, 177, 0.30)"}
                  isBgColor={"white"}
                  type='number'
                  name="weeklyIncrement"
                  value={parseFloat(weeklyIncrement)}
                  onChange={this.onChangeInput}
                  pattern='[0-9]*'
                  min="0"
                  onKeyPress={(e) => this.isNumberKey(e)}
                  placeholder={activityType==="Total Steps"?"Enter E.g. 500 Steps":activityType==="Total Calories"?"Enter E.g. 30 Calories":"Enter E.g. 25 Miles"}
                />
              </SurvivorAverage>}
                </SurvivorChallengeSection>
                {survivorChallenge || groupChallenge ? <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 5px 0px'}}/>: null}

                <TitleContainer widthValue="70%" height="auto">
                  <CommonDropdown
                    tileContainerStyle={{ width: "100%", padding: "0px 0px 0px 0px" }}
                    dropdownStyle={{ top: "90px" }}
                    labelText={t("Challenge point value")}
                    isRequired={true}
                    dropdownOpen={pointsArrow}
                    placeholder={t("Select point value")}
                    title={challengePointKey=="0 Points"?"No Points":challengePointKey?t(challengePointKey) : t("Select point value")}
                    id="dropdown-recurring"
                    onClick={() => this.setState({ pointsArrow: !pointsArrow })}
                    data={challengePoints}
                    onSelect={this.onSelectPoints}
                    active={challengePointValue}
                    onSelectParameter={["value", "key"]}
                    // valueString={"Points"}
                    itemValue={true}
                    valueIndex={1}
                    labelMargin={"0px"}
                    t={t}
                  />
                </TitleContainer>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  toggleRadioButton = (name, value) => {
    this.setState({ [name]: value }, () => { });
    if(name=="selectedDailyGoalOption"&&value===1){
      this.setState({
        dailyGoal:null
      })
    }
  };

  dateRadioButton = (name, value ) => {
    this.setState({ [name]: value });
    if(value===1){
      this.setState({
        date: "",
        endDate:''
      })
    }
    if(value===0){
      this.setState({
        date: moment().format('MM/DD/YYYY'),
        endDate: moment().format('MM/DD/YYYY')
      })
    }
  };

  onSelectSurveyType = (name) => {
    this.setState({
      surveyType: name.survey_name,
      surveyId: name.id
    });
  };

  selectAllCompanies = (e) => {
    e.preventDefault();
    let invitedCompanies = [];
    const { allCompanies } = this.props;
    if (this.state.companies.length === allCompanies.length) {
      invitedCompanies = [];
    } else {
      allCompanies && allCompanies.map((company) => {
        invitedCompanies.push(company.id);
      });
    }
    this.setState({
      companies: invitedCompanies
    })
  };

  onCheckboxChange = (element) => {
    const { companies } = this.state;
    if (companies.includes(element)) {
      let index = companies.findIndex((item) => item === element);
      if (index > -1) {
        companies.splice(index, 1);
      }
    } else {
      companies.push(element);
    }
    this.setState({
      companies: companies
    })
  };

  selectAllLocation = (e, locationDetails) => {
    e.preventDefault();
    const { city_location } = this.state;
    if (city_location.length == locationDetails.length) {
      this.setState({
        city_location: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < locationDetails.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        city_location: arr,
      });
    }
  };

  onChangeLocation = (id) => {
    const { city_location } = this.state;
    if (city_location.includes(id)) {
      let index = city_location.findIndex((item) => item === id);
      if (index > -1) {
        city_location.splice(index, 1);
      }
    } else {
      city_location.push(id);
    }
    this.setState({
      city_location: city_location,
    });
  };

  removeCommaFromFirstPlace = (str) => {
    if (str.charAt(0) === ",") {
      return str.substring(1);
    }
    return str;
  };

  selectAllTeams = (e) => {
    e.preventDefault();
    let invitedTeams = [];
    const { allTeams } = this.state;
    if (this.state.teams.length === allTeams.length) {
      invitedTeams = [];
    } else {
      allTeams.map((company) => {
        invitedTeams.push(company.id);
      });
    }
    this.setState({
      teams: invitedTeams
    })
  };

  onTeamsCheckboxChange = (element) => {
    const { teams } = this.state;
    if (teams.includes(element)) {
      let index = teams.findIndex((item) => item === element);
      if (index > -1) {
        teams.splice(index, 1);
      }
    } else {
      teams.push(element);
    }
    this.setState({
      teams: teams
    })
  };

  stepThreeForm = () => {
    const {
      step,
      selectedOption,
      surveyType,
      surveyArrow
    } = this.state;
    const {surveyLibraries, t} = this.props;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container height="100%" stepBackground="#005C87" color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} heading={this.stepsHeadingEvent}/>
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("Set the survey and edit the challenge")}</div>
              </div>
              <div className="formBody">
                <StyledLabel color="#005C87">
                  {this.props.t("Would you like to add the survey")} ?
                </StyledLabel>
                <div className="radioButtonDiv">
                  <span onClick={() => this.toggleRadioButton( "selectedOption",0)}>
                    <RadioButton
                      id="1"
                      handler={this.toggleRadioButton}
                      value={0}
                      isChecked={selectedOption === 0}
                      label={this.props.t("Yes")}
                      challengeCreate={true}
                    />
                  </span>
                  <span onClick={() => this.toggleRadioButton( "selectedOption",1)}>
                    <RadioButton
                      id="2"
                      handler={this.toggleRadioButton}
                      value={1}
                      isChecked={selectedOption === 1}
                      label={this.props.t("No")}
                      challengeCreate={true}
                    />
                  </span>
                </div>
              

                {selectedOption===0?
                  <TitleContainer widthValue="70%" height="auto">
                    <CommonDropdown
                      tileContainerStyle={{ width: "100%", padding: "0px 0px 20px 0px" }}
                      labelText={t("List of surveys")}
                      isRequired={true}
                      dropdownOpen={surveyArrow}
                      placeholder={t("Select survey")}
                      title={surveyType ? t(surveyType) : t("Select survey")}
                      id="dropdown-recurring"
                      onClick={() => this.setState({ surveyArrow: !surveyArrow })}
                      data={surveyLibraries}
                      onSelect={this.onSelectSurveyType}
                      onSelectParameter={["survey_name", "id"]}
                      active={surveyType}
                      itemValue={true}
                      displayValue={true}
                      // valueString={""}
                      valueIndex={0}
                      t={t}
                      selectedClassCategory={surveyType}
                      isChallengeWellness={false}
                      createEventUI={false}
                      labelMargin={"0px"}
                    />
                  </TitleContainer>:null}

              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  setDatePickerWrapper = (node) => {
    this.datePickerWrapper = node;
  };

  changeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if (showDateTimeInput === 'date') {
      this.setState({
        date: moment(e._d).format('MM/DD/YYYY'),
        time: moment(e._d).format('hh:mm a'),
        endDate: moment(e._d).format('MM/DD/YYYY'),
        endTime: '11:59 pm',
        openDateTimePicker: '',
      });
    } else if (stateName.includes('ate')) {
      if(stateName === 'date'){
        this.setState({
          openDateTimePicker: '',
          [stateName]: moment(e._d).format('MM/DD/YYYY'),
          endDate:moment(e._d).format('MM/DD/YYYY'),
        });
      }else {
        this.setState({
          openDateTimePicker: '',
          [stateName]: moment(e._d).format('MM/DD/YYYY'),
        });

      }
    } else {
      this.setState({
        [stateName]: moment(e._d).format('hh:mm a'),
        openDateTimePicker: stateName
      });
    }
  };

  showDatePicker = (value) => {
    if (this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: ''
      });
    } else {
      this.setState({
        openDateTimePicker: value
      });
    }
  };

  onChangeEmail = (key) => {
    const { emailOption } = this.state;
    emailOption[key] = emailOption[key] === 0 ? 1 : 0;
    this.setState({ emailOption: emailOption });
  }

  checkBase64(image) {
    if (image) {
      if (image.includes('base64')) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  onSubmit = () => {
    // e.preventDefault();
    const { title, date, description, imgSrc, endDate, activityType, challengePointValue, challengeMode, weeklyIncrement,
      survivorAverage, imageName, companies, location, isSpouse, isDependent, isEmployee, challengeLength, survivorChallenge, groupChallenge, /*imageUpdated,*/ emailOption, locationPermission, surveyId, selectedOption, dailyGoal, selectedDailyGoalOption, is_team_challenge, teams } = this.state;
    const {  saveChallengeData, companyDetails, createLibrary, activityChallengeById, editLibrary, editChallengeLibrary, history } = this.props;
    let survivorEndDate = moment(moment(date).add((challengeLength * 7) - 1, 'days')._d).format('MM/DD/YYYY');
    const challengeType = survivorChallenge ? 'survivor' : groupChallenge ? 'new_group' : 'group';
    this.setState({ buttonStatus: true });
    const challenge = {
      title,
      companies: companies,
      body: description,
      image: imgSrc,
      event_type: groupChallenge ? activityType === 'Total Group Steps' ? 'Total Steps' : activityType === 'Total Group Calories' ? 'Total Calories' : 'Total Distance' : activityType,
      end_date: createLibrary || editLibrary ? null : (survivorChallenge || groupChallenge) ? moment(survivorEndDate).format('YYYY-MM-DD') + ' ' + moment('11:59:59 PM', "hh:mm:ss a").format("HH:mm:ss") : moment(endDate).format('YYYY-MM-DD') + ' ' + moment('11:59:59 PM', "hh:mm:ss a").format("HH:mm:ss"),
      start_date: createLibrary || editLibrary ? null : moment(date).format('YYYY-MM-DD') + ' ' + moment('12:00:00 AM', "hh:mm:ss a").format("HH:mm:ss"),
      survivor_average: survivorAverage,
      weekly_increment: survivorChallenge ? weeklyIncrement : null,
      challenge_point: challengePointValue,
      challenge_mode: survivorChallenge ? challengeMode : '',
      challenge_type: challengeType,
      isSurvivorChallenge: survivorChallenge,
      isGroupChallenge: groupChallenge,
      ...emailOption,
      survey_id: selectedOption === 1 ? null : surveyId,
      daily_goal: selectedDailyGoalOption === 1 ? null : dailyGoal,
      is_team_challenge:is_team_challenge, 
      teams:teams
    };
    // activityChallengeById ? challenge.convert_image = !this.checkBase64(imgSrc) : null;
    if(activityChallengeById){
      challenge.convert_image = !this.checkBase64(imgSrc)
    }
    if (editLibrary) {
      challenge.id = this.props.survivorChallengeData['id'];
      challenge.imageUpdated = this.checkBase64(imgSrc) ? 1 : 0;
    }
    if (locationPermission) {
      challenge.company_city_states = location;
      challenge.challenge_for_spouse = isSpouse ? 1 : 0;
      challenge.challenge_for_dependent = isDependent ? 1 : 0
      if (this.props.locationDetails && this.props.locationDetails.length > 0) {
        if(location.length > 0){
          challenge.is_for_employee = 1
        }
        else{
          challenge.is_for_employee = 0
        }
      }
      else {
        if (companyDetails['spouse_or_dependent']) {
          challenge.is_for_employee = isEmployee ? 1 : 0
        }
        else {
          challenge.is_for_employee = 1
        }
      }
    }

    saveChallengeData({ ...challenge, imageName });
    editChallengeLibrary(challenge, history.location.pathname.includes("/company/challenges/")?`/company/challenges/categories/${challengeType}`:`/challenges/categories/${challengeType}`);
  };


  DropdownIndicator = () => (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.431681 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.431681 17.609L8.44122 10L0.43196 2.39098C-0.143614 1.84394 -0.143614 0.957264 0.43196 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64172 12 10Z"
        fill="#0D4270"
      />
    </svg>
  );

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuIsOpen: !prevState.menuIsOpen,
    }));
  };

  updateStep = (value) => {
    this.setState({ step: value, buttonClickScrollTop: true, buttonStatus: false });
  };

  onSelectPoint = (name, value, key ) => {
    this.setState({
      [name]: value,
      challengePointKey: key
    });
  };


  onSelectPoints = (name) => {
    this.setState({
      [name]: name.value,
      challengePointKey: name.key,
      challengePointValue: name.value,
    });
  };

  

  render() {
    const { step, buttonStatus, title, imgSrc, description, activityType, activityChallengeType, survivorChallenge, groupChallenge, weeklyIncrement, survivorAverage, selectedOption, surveyType} = this.state;
    return (
      <React.Fragment>
        {step === 1?this.stepOneForm(): step === 2? this.stepTwoForm(): step === 3 && this.stepThreeForm()}
        {step === 1 ? (
          <ButtonContainer>
            <Main>
              <CommonButton
                btnType={"squareIcon"}
                onClick={() => this.updateStep(2)}
                title={this.props.t("Next")}
                styles={{color:"#007AB1" }}
                disabled={!title||!imgSrc|| !description}
                nextIcon={NextArrows("white")}
              />
            </Main>
          </ButtonContainer>
        ) : step === 2 ? (
          <ButtonContainer>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center" }}
                onClick={() => this.updateStep(1)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"}
                disabled={!activityType || !activityChallengeType || (survivorChallenge && (!weeklyIncrement || !survivorAverage)) || (groupChallenge && !survivorAverage) }
                styles={{marginAuto:"auto", color:"#007AB1" }}
                onClick={() => this.updateStep(3)}
                title={this.props.t("Next")}
                nextIcon={NextArrows("white")}
              />
            </div>
          </ButtonContainer>
        ) : (
          step === 3 && (
            <ButtonContainer>
              <div className="wrapper">
                <CommonButton
                  btnType={"squareIcon"}
                  styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center" }}
                  onClick={() => this.updateStep(2)}
                  title={this.props.t("Go Back")}
                  icon={Arrows()}
                />
                <CommonButton
                  btnType={"squareIcon"}
                  disabled={selectedOption===0&&!surveyType||buttonStatus}
                  styles={{marginAuto:"auto", color:"#007AB1" }}
                  // styles={{color:"linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%)", marginAuto:'auto'}}
                  onClick={!buttonStatus? () => this.onSubmit():""}
                  title={this.props.t("Save Challenge")}
                />
              </div>
            </ButtonContainer>
          )
        )}
      </React.Fragment>
    );
  }
}

EditActivityChallenge.propTypes = {
  isSurvivorChallenge: PropTypes.bool.isRequired,
  createChallenge: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onHide: PropTypes.func,
  MandatoryFields: PropTypes.array.isRequired,
  survivorChallengeData: PropTypes.object,
  saveChallengeData: PropTypes.func.isRequired,
  allCompanies: PropTypes.array,
  fetchAllCompanies: PropTypes.func.isRequired,
  role: PropTypes.string,
  fetchCompanyForUser: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  locationDetails: PropTypes.array,
  companyDetails: PropTypes.object,
  allowChallengeEventForSpouseDependent: PropTypes.func,
  isGroupChallenge: PropTypes.bool,
  activityChallengeById: PropTypes.bool,
  createLibrary: PropTypes.bool,
  editChallengeLibrary: PropTypes.func,
  editLibrary: PropTypes.bool,
  userPermissions: PropTypes.array,
  surveyLibraries: PropTypes.array,
  getSurveyLibraries: PropTypes.func,
  t: PropTypes.func,
  fetchCompanyTeamUsers:PropTypes.func,
  challengeDetails: PropTypes.array,
  getChallengeDetails: PropTypes.func,
  getCompanyLocation: PropTypes.func
};

const mapStateToProps = (state) => ({
  allCompanies: state.challenges.allCompanies,
  userCompany: state.profileData.userCompany,
  locationDetails: state.register.locationDetails,
  companyDetails: state.profileData.companyDetails,
  userPermissions: state.profileData.userPermissions,
  surveyLibraries: state.surveys.surveyLibraries,
  challengeDetails: state.challenges.activityDetailsById
});

const mapDispatchToProps = (dispatch) => ({
  saveChallengeData: (data) => dispatch(saveChallengeData(data)),
  fetchCompanyForUser: () => dispatch(getUserCompanyDetails()),
  allowChallengeEventForSpouseDependent: () => dispatch(allowChallengeEventForSpouseDependent),
  editChallengeLibrary: (challenge, path) => dispatch(editChallengeLibraryV2(challenge, path)),
  fetchCompanyTeamUsers: (companyId) => dispatch(fetchCompanyTeamUsers(companyId)),
  getChallengeDetails: (id) => dispatch(getActivityChallengeById(id)),
  getCompanyLocation: (id) => dispatch(getCompanyLocation(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditActivityChallenge));
